import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import '../App.css'
import Company from '../pages/Company';

export default function Navbar() {
    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);


    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
        console.log(isSidebarVisible)
      };

      const toggleMobileMenu = () => { 
        setMobileMenuOpen(!isMobileMenuOpen);
      };

      function hideSideBar(){
          setSidebarVisible(false)
      }

    return (
        <div>
        <header
            style={{ background: "#f0f0f0", backdropFilter: 'blur(30px)' }}
            className="h-14 fixed top-0 left-0 right-0 rounded-md mx-6 my-4  px-1 py-4 flex justify-between items-center z-50"
        >
            {/* Logo */}
            <div className='flex items-center'>
    <NavLink to="/">
    <img src="logo.png" alt="Logo" className="w-10 h-10" /> 
          </NavLink>
          <NavLink to="/" className="hidden md:block">
            <p className="text-[23px] font-semibold text-black sm:text-[18px] md:text-[20px] lg:text-[23px] hover:text-blue-400 px-4">
              Aerosearch Technologies
            </p>
          </NavLink>
            </div>

            {/* Hamburger Menu for Mobile */}
        <button
          onClick={toggleMobileMenu}
          className="md:hidden text-black focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>


{/* Desktop Navigation Links */}
<nav className="hidden md:flex space-x-8">
          <button
            onClick={toggleSidebar}
            className="font-semibold text-black hover:text-blue-400"
          >
            About Us
          </button>
          <NavLink to="/" className="font-semibold text-black hover:text-blue-400">
            Drone Education
          </NavLink>
          <NavLink to="/" className="font-semibold text-black hover:text-blue-400">
            Drone-Lab Setup
          </NavLink>
          <NavLink to="/" className="font-semibold text-black hover:text-blue-400">
            Drone Software
          </NavLink>
        </nav>

        {/* Contact Button */}
        <div className="hidden md:flex items-center">
          <button
            onClick={() => alert("Email: aerosearchtech@gmail.com")}
            className="bg-black text-white px-4 py-2 rounded-md"
          >
            Contact
          </button>
        </div>
      </header>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <nav className="absolute top-14 left-0 w-full bg-white shadow-md z-50 md:hidden">
          <ul className="flex flex-col space-y-4 py-4 px-6">
            <button
              onClick={toggleSidebar}
              className="font-semibold text-black hover:text-blue-400"
            >
              About Us
            </button>
            <NavLink
              to="/"
              className="font-semibold text-black hover:text-blue-400 block"
            >
              Drone Education
            </NavLink>
            <NavLink
              to="/"
              className="font-semibold text-black hover:text-blue-400 block"
            >
              Drone-Lab Setup
            </NavLink>
            <NavLink
              to="/"
              className="font-semibold text-black hover:text-blue-400 block"
            >
              Drone Software
            </NavLink>
            <button
              onClick={() => alert("Email: aerosearchtech@gmail.com")}
              className="bg-black text-white px-4 py-2 rounded-md"
            >
              Contact
            </button>
          </ul>
        </nav>
      )}

        {isSidebarVisible===true && <Company function1={hideSideBar}/>}
        
        </div>
    );
}