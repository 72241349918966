import React, {useState} from 'react'
import Image1 from '../assets/images/shared/drone.png'
// import Image2 from '../assets/images/shared/2.gif'
// import Image3 from '../assets/images/shared/3.gif'
import Company from './Company'


export default function Home() {
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(true);
    console.log(isSidebarVisible)
  };
  function hideSideBar(){
    setSidebarVisible(false)
  }
  return (
    <div className=" px-0 bg-black">
      {/* Full-width GIF Section with Text and Button on the Left */}
      {/* <div className="relative w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] flex items-start justify-start mb-8"> */}
      <div className="relative w-full h-[88vh] sm:h-[88vh] md:h-[88vh] lg:h-[88vh] flex items-start justify-start mb-8">
        {/* GIF as background covering the entire container */}
        <div
          className="absolute inset-0 w-full h-[91vh] bg-cover bg-center bg-repeat"
          style={{
            backgroundImage: `url(${Image1})`, 
            // backgroundRepeat: "repeat", // Enable image repetition
            // backgroundSize: "100% 100%", // Adjust size if needed
          }}
        ></div>

        {/* Overlay content aligned to the left */}
        <div className="relative z-10 mt-14 text-left text-white p-4 md:p-8 lg:p-10 max-w-[50%]">
          <h2 className="text-[40px]  sm:text-[40px] md:text-[40px] lg:text-[40px] font-semibold mb-4 leading-tight">
          Research-Driven Innovations
          </h2>
          {/* <p className="text-[18px] text-white sm:text-[20px] md:text-[24px] lg:text-[28px] mb-6">
          </p> */}
        </div>

        {/* Optional overlay to darken the GIF background */}
        <div className="absolute inset-0 w-full h-[91vh] bg-black opacity-30"></div>
      </div>

{/* ================================================================================ */}

      {/* <div className="relative w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] flex items-start justify-start mb-8"> */}
        {/* GIF as background covering the entire container */}
        {/* <div
          className="absolute inset-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${Image2})`, // Replace with actual GIF URL
          }}
        ></div> */}

        {/* Overlay content aligned to the left */}
        {/* <div className="relative z-10 mt-14 text-left text-white p-8 md:p-16 lg:p-20 max-w-[50%]">
          <h2 className="text-[32px]  sm:text-[40px] md:text-[50px] lg:text-[60px] font-semibold mb-4 leading-tight">
          Drone-as-a-Service (DaaS)
          </h2>
          <p className="text-[18px] text-white sm:text-[20px] md:text-[24px] lg:text-[28px] mb-6">
          </p>
        </div> */}

        {/* Optional overlay to darken the GIF background */}
        {/* <div className="absolute inset-0 w-full h-full bg-black opacity-30"></div>
      </div> */}


      {/* ================================================================================ */}

      {/* <div className="relative w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] flex items-start justify-start mb-8"> */}
        {/* GIF as background covering the entire container */}
        {/* <div
          className="absolute inset-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${Image3})`, // Replace with actual GIF URL
          }}
        ></div> */}

        {/* Overlay content aligned to the left */}
        {/* <div className="relative z-10 mt-14 text-left text-white p-8 md:p-16 lg:p-20 max-w-[50%]">
          <h2 className="text-[32px]  sm:text-[40px] md:text-[50px] lg:text-[60px] font-semibold mb-4 leading-tight">
          Drone Education for STEM
          </h2>
          <p className="text-[18px] text-white sm:text-[20px] md:text-[24px] lg:text-[28px] mb-6">
          </p>
        </div> */}

        {/* Optional overlay to darken the GIF background */}
        {/* <div className="absolute inset-0 w-full h-full bg-black opacity-30"></div>
      </div> */}

      <div className="flex flex-col items-center justify-center ">
      </div>
      {isSidebarVisible===true && <Company function1={hideSideBar}/>}
    </div>
  )
}
