import React from 'react';

export default function Footer() {
    return (
    <footer className=" bg-gray-100 py-5 px-5 md:px-20">

      {/* <div className="grid grid-cols-2 md:grid-cols-2 gap-8"> */}
       
        {/* <div>
          <h3 className="font-semibold">Our Services</h3>
          <ul>
            {['Drone Technology', 'Drone-as-a-Service (DaaS)', 'Mititary Operations with Drones', 'Drone Education for STEM', 'Digital Transformation', 'Energy & Utilities Industries', 'Drone Delivery'].map((item, index) => (
              <li key={index} className="border-b border-black py-2">
                {item}
              </li>
            ))}
          </ul>
        </div> */}


        {/* <div>
          <h3 className="font-semibold">Industries We Serve</h3>
          <ul>
            {['Energy & Utilities', 'Agriculture & Farming', 'Construction & Infrastructure', 'Logistics & Supply Chain', 'Defense & Public Safety', 'Mining & Environmental', 'For Other Industries'].map((item, index) => (
              <li key={index} className="border-b border-black py-2">
                {item} 
              </li>
            ))}
          </ul>
        </div> */}
        {/* </div> */}


      {/* <div style={{ borderTop: "2px solid #000 ", marginLeft: 0, marginRight: 0 }}>
<br></br>
        <p>© Aerosearch Technologies Private Limited, India.</p>

        <p>Make in India</p>
        </div> */}

        {/* <div style={{ marginLeft: 0, marginRight: 0 }}> */}
{/* <br></br> */}
        <p>© Aerosearch Technologies Private Limited, India, 2024.</p>

        <p>Make in India</p>
        {/* </div> */}
    </footer>
     
  );
}
